<template>
  <admin>
    <page-header>
      <template v-slot:action>
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          large
          color="grey"
          class="white--text"
          exact
          @click="downloadReport"
          :loading="downloading"
          :disabled="downloading"
        >
          <v-icon small left>mdi-cloud-download</v-icon>
          <span v-text="'Download'"></span>
        </v-btn>
      </template>
    </page-header>

    <toolbar-menu
      ref="toolbar"
      :filterDateRange="true"
      :filter-select-dealer="true"
      @update:search="setSearch"
      @update:daterangesurvey="setDateRange"
      @update:dealer="handleFilterDealer"
    ></toolbar-menu>

    <v-data-table
      :headers="resources.headers"
      :options.sync="resources.options"
      :server-items-length="resources.total"
      :items="items"
      :loading="resources.loading"
    >
      <template v-slot:item.id="{ item }">
        <v-btn @click="preview(item)" text color="primary" icon>
          {{ item.id }}
        </v-btn>
      </template>

      <template v-slot:item.customer_fullname="{ item }">
        {{ item.customer_fullname }} <br />
        <small>{{ item.customer_mobile }}</small> <br />
        <small>{{ item.customer_email }}</small>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          label
          :color="item.status == 'sent' ? 'green' : 'red'"
          text-color="white"
          >{{ item.status }}</v-chip
        >
      </template>
    </v-data-table>

    <template v-if="previewDialog">
      <v-dialog
        v-model="previewDialog"
        max-width="900px"
        class="overflow-hidden"
      >
        <v-card class="pa-4">
          <v-card-title>
            <h2 class="mb-1">#{{ dialogItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <!-- Background Details -->
            <v-simple-table>
              <tbody>
                <tr>
                  <td class="font-weight-bold">
                    {{ "From" }}
                  </td>
                  <td>
                    {{ dialogItem.sender }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "To" }}
                  </td>
                  <td>
                    {{ dialogItem.recipient }}
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">
                    {{ "Subject" }}
                  </td>
                  <td>
                    {{ dialogItem.title }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <hr style="border-top: 1px solid var(--sidebar-border)" />
            <br />
            <div v-html="dialogItem.message"> </div>
            <!-- Background Details -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              color="grey"
              exact
              class="ma-1 white--text px-5"
              @click="previewDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

  </admin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";

export default {
  data() {
    return {
      searchText: "",
      startDate: "",
      endDate: "",
      searchText: "",
      orderBy: "ASC",
      downloading: false,
      previewDialog: false,
      dialogItem: null,
      dealers: [],
      resources: {
        total: 0,
        loading: true,
        headers: [
          {
            text: "ID",
            align: "left",
            value: "id",
          },
          {
            text: "Survey",
            align: "left",
            value: "survey_name",
            sortable: true,
          },
          {
            text: "Customer",
            align: "left",
            value: "customer_fullname",
            sortable: true,
          },
          {
            text: "Dealer",
            align: "left",
            value: "dealer_name",
            sortable: true,
          },
          {
            text: "Status",
            align: "left",
            value: "status",
          },
          {
            text: "Date Created",
            align: "left",
            value: "created_at",
          },
        ],
        options: {},
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      },
      items: [],
    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems({page, itemsPerPage});
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      emailNotifications: "survey/GET_EMAIL_NOTIFICATION",
    }),
  },
  watch: {
    "resources.options": {
      handler() {
        const self = this;

        const { sortBy, sortDesc, page, itemsPerPage } = self.resources.options;

        let sort = {
          orderBy: sortBy.length ? sortBy[0] : 'created_at',
          sortBy: !sortDesc[0] ? 'DESC' : 'ASC'
        };

        self.getItems({page, itemsPerPage, q: '', dates: [], sortBy: sort});
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getEmailNotifications: "survey/getEmailNotifications",
      downloadEmailNotificationReport: "survey/downloadEmailNotificationReport",
    }),

    preview(item) {
      this.dialogItem = item;
      this.previewDialog = true;
    },

    async handleFilterDealer(value) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

      let dates = [];

      if (this.startDate) {
        dates[0] = this.startDate;
        dates[1] = this.endDate;
      }

      this.dealers = value ? [value] : [];

      this.getItems({page, itemsPerPage, q: this.searchText, dates, dealers: this.dealers});
    },

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

      this.searchText = e.target.value;

      let dates = [];

      if (this.startDate) {
        dates[0] = this.startDate;
        dates[1] = this.endDate;
      }

      this.getItems({page, itemsPerPage, q: e.target.value, dates, dealers: this.dealers});

      this.$refs.toolbar.items.isSearching = false;

    }, 300),

    setDateRange: debounce(async function (e) {
      const self = this;
      let dates = [];
      let startDate = "";
      let endDate = "";

      if (e) {
        startDate = e[0];
        endDate = e[1];
        dates = [startDate, endDate];
      }

      self.startDate = startDate;
      self.endDate = endDate;

      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;

      this.getItems({page, itemsPerPage, q: self.searchText, dates: dates, dealers: this.dealers});
    }, 300),

    getItems(params) {
      const self = this;

      self.resources.loading = true;

      this.getEmailNotifications(params).then((data) => {
        this.loading = false;
        self.items = self.emailNotifications.data;
        self.resources.loading = false;

        self.resources.total = self.emailNotifications.meta.total;

        self.$refs.toolbar.items.isSearching = false;
      });
    },
    downloadReport() {
      const self = this;

      if (!self.downloading) {
        self.downloading = true;

        let date = [];

        if (self.startDate) {
          date[0] = self.startDate;
          date[1] = self.endDate;
        }

        this.downloadEmailNotificationReport({
          q: self.searchText,
          date: date,
        }).then(function (data) {
          self.downloading = false;

          // window.location = data.url;
          if (data.url === undefined) {
            window.location = data.data.url;
          } else {
            window.location = data.url;
          }
        });
      }
    },
  },
  mounted() {},
};
</script>